/**
 *
 * Conditionnal forms display
 *
 */

(function () {
  var $containers = $('.js-form');
  var $funnel = false;
  var $funnel_select = false;
  var $funnel_anppi = false;
  var $funnel_anppi_select = false;
  var $funnel_ampps = false;
  var $funnel_ampps_select = false;

  var hide = function ($container) {
    $container.attr('aria-hidden', "true");
    $container.hide();
  }

  var show = function ($container) {
    $container.attr('aria-hidden', "false");
    $container.show();
  }

  var hideAll = function () {
    $containers.each(function (index, container) {
      var $container = $(container);

      if(!$container.attr('data-form-funnel')) {
        hide($container);
      }
    });
  }

  var hideANPPI = function () {
    $containers.each(function (index, container) {
      var $container = $(container);

      if(!$container.attr('data-form-funnel') && !$container.attr('data-form-funnel-anppi')) {
        hide($container);
      }
    });
  }
  var hideAMPPS = function () {
    $containers.each(function (index, container) {
      var $container = $(container);

      if(!$container.attr('data-form-funnel') && !$container.attr('data-form-funnel-ampps')) {
        hide($container);
      }
    });
  }

  $containers.each(function (index, container) {
    var $container = $(container);
    var $form = $container.find('form');
    var $submit = $form.find('.js-form-submit');

    if($container.attr('data-form-funnel')) {
      $funnel = $container;
      $funnel_select = $form.find('select');
      $submit.remove();
      show($container);

      $form.on('change', function (event) {
        var value = $funnel_select.val();
        hideAll();
        show($('#form-' + value));
      });

    } else if($container.attr('data-form-funnel-anppi')) {
        $funnel_anppi = $container;
        $funnel_anppi_select = $form.find('select');
        $submit.remove();

        $form.on('change', function (event) {
          var value = $funnel_anppi_select.val();
          hideANPPI();
          show($('#form-' + value));
        });

    } else if($container.attr('data-form-funnel-ampps')) {
        $funnel_ampps = $container;
        $funnel_ampps_select = $form.find('select');
        $submit.remove();

        $form.on('change', function (event) {
          var value = $funnel_ampps_select.val();
          hideAMPPS();
          show($('#form-' + value));
        });

    } else {
      $container.find('.js-form-remove').remove();
      hide($container);
    }

  });

  $('.js-toggler').each(function() {
    var $toggler = $(this);
    var $to_toggl = $($toggler.data('toggl-element'));

    $toggler.on('change', function() {
      if (this.tagName == 'SELECT') {
        var $input = $(this);
        var value = $input.val();

        if (typeof(value) == 'string' && value.toLowerCase() == 'autre') {
          $to_toggl.show().find('input, select').prop('disabled', false);
        }
        else {
          $to_toggl.hide().find('input, select').prop('disabled', true);
        }
      }
      else {
        var input_name = $(this).attr('name');
        var $input = $('input[name="' + input_name + '"]').filter(':checked');
        var value = $input.val();

        $to_toggl.each(function() {
          var $this = $(this);
          var invert = $this.hasClass('js-toggl-invert');
          if (invert) {
            if ($.inArray(value, ['1']) !== -1) {
              $this.hide().find('input, select').prop('disabled', true);
            }
            else {
              $this.show().find('input, select').prop('disabled', false);
            }
          }
          else {
            if ($.inArray(value, ['1', 'oui']) !== -1) {
              $this.show().find('input, select').prop('disabled', false);
            }
            else {
              $this.hide().find('input, select').prop('disabled', true);
            }
          }
        });
      }
    }).trigger('change');
  });

  if($funnel && $funnel.attr('data-form-active')) {

    if($funnel_anppi && $funnel_anppi.attr('data-form-anppi-active')) {

      $funnel_select.val($funnel_anppi.attr('data-form-active'));
      $funnel_anppi_select.val($funnel_anppi.attr('data-form-anppi-active'));
      $funnel_anppi_select.closest('form').trigger('change');

    } else {

      $funnel_select.val($funnel.attr('data-form-active'));
      $funnel_select.closest('form').trigger('change');

    }

  }

})();
