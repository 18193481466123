/**
 *
 * Mozaic toggle comportment
 *
 */

(function() {

  var hideItem = function ($summary, $details) {
    $summary.attr('aria-expanded', "false");
    $details.attr('aria-hidden', "true");
  };

  var showItem = function ($summary, $details) {
    $summary.attr('aria-expanded', "true");
    $details.attr('aria-hidden', "false");
  };

  var hideItems = function ($items) {
    $items.each(function(index, item) {
      var $item = $(item);
      var $summary = $item.find('.js-mozaic-summary');
      var $details = $item.find('#' + $summary.attr('aria-controls'));
      hideItem($summary, $details);
      $item.removeClass('is-open');
    });
  };

  $('.js-mozaic').each(function(index, mozaic) {
    var $mozaic = $(mozaic);
    var $items = $mozaic.find('.js-mozaic-item');

    $items.each(function(index, item) {
      var $item = $(item);
      var $summary = $item.find('.js-mozaic-summary');
      var $details = $item.find('#' + $summary.attr('aria-controls'));
      var $close = $item.find('.js-mozaic-close');

      $item.on('click', function(event) {
        hideItems($items);
        showItem($summary, $details);
        $item.addClass('is-open');
      });

      $close.on('click', function(event) {
        event.stopPropagation();
        hideItem($summary, $details);
        $item.removeClass('is-open');
      });
    });
  });
})();
